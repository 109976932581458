
































	import { Vue, Component } from 'vue-property-decorator'
	import axios from 'axios'
	import { apiBuilder } from '../../config'

	@Component
	export default class ForgotPassword extends Vue {
		private rules = {
			required: (value: string | number) => !!value || 'Không Được Trống.',
			email: (value: string) => {
				const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				return pattern.test(value) || 'Email Không Hợp Lệ'
			},
		}

		private formData = {
			email: '',
		}

		private loading = false

		private sendRequest() {
			const form = this.$refs.form as Vue & { reset: () => boolean; validate: () => boolean }

			if (form.validate()) {
				this.loading = true

				const apiUrl = apiBuilder('account/forgotpasswordrequest.php')

				const param = {
					params: {
						email: this.formData.email,
					},
				}

				axios
					.post(apiUrl, null, param)
					.then((res) => {
						const error = res.data.error

						if (error == '281') {
							return this.$swal({
								icon: 'error',
								title: 'Lỗi !',
								text: 'Email Không Tồn Tại !',
							})
						} else if (error == '282') {
							return this.$swal({
								icon: 'error',
								title: 'Lỗi !',
								text: 'Email Sai Định Dạng !',
							})
						} else if (error != '280'){
                            return this.$swal({
								icon: 'error',
								title: 'Lỗi !',
								text: 'Server Error ! Please Reload',
							})
                        }

						form.reset()

						this.$swal({
							icon: 'success',
							title: 'Thành Công !',
							text: 'Mã Đã Được Gửi Về Email Của Bạn!',
						}).then(() => {
							this.$router.replace({ name: 'ResetPassword' })
						})

						setTimeout(() => {
							this.$router.replace({ name: 'ResetPassword' })
						}, 1000)
					})
					.catch((e) => {
						return this.$swal({
							icon: 'error',
							title: 'Lỗi !',
							text: e,
						})
					})

				this.loading = false
			}
		}
	}
